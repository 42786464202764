import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import counts from './modules/counts';
import global from './modules/global';
import lessons from './modules/lessons';
import products from './modules/products';

export default createStore({
  modules: {
    auth, 
    utils,
    counts,
    global,
    lessons,
    products
  },
    
  plugins: [createPersistedState(
    {
      key : 'smarttraderuganda.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
