export const namespaced = true
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {

    state : {
        topics :'',
        adverts :'',
        grades :'',
        teachers :'',
        contests :'',
        impacts :'',
    },

    getters : {
        topics: (state )=> state.topics,
        adverts: (state )=> state.adverts,
        grades: (state )=> state.grades,
        teachers: (state )=> state.teachers,
        contests: (state )=> state.contests,
        impacts: (state )=> state.impacts,
    },

    mutations : { 
        settopics :(state,topics ) => (state.topics = topics), 
        setadverts :(state,adverts ) => (state.adverts = adverts),
        setgrades :(state,grades ) => (state.grades = grades),
        setteachers :(state,teachers ) => (state.teachers = teachers),
        setcontests :(state,contests ) => (state.contests = contests),
        setimpacts :(state,impacts ) => (state.impacts = impacts),
    },

    actions :{
        async get_adverts( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_adverts')
            return new Promise((resolve, reject) => {
            axios.post("/open/adverts?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setadverts', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_topics( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_topics')
            return new Promise((resolve, reject) => {
            axios.post("/open/topics?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('settopics', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_grades( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_grades')
            return new Promise((resolve, reject) => {
            axios.post("/open/grades?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setgrades', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_teachers( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_teachers')
            return new Promise((resolve, reject) => {
            axios.post("/open/teachers?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setteachers', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_contests( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_contests')
            return new Promise((resolve, reject) => {
            axios.post("/open/contests?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setcontests', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_impacts( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_impacts')
            return new Promise((resolve, reject) => {
            axios.post("/open/impacts?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setimpacts', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

    }
}