import store from '@/store'

const adminguard = (to, from, next) => {
  return next()
    // if(localStorage.getItem('smarttraderauthtoken' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.name ){
    //     return next()
    // }else{
    //     return next({ name: 'login' })
    // }
};


let routes = [
    { 
        path: "/",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/main.vue"),
        title:'',

          children: [

            {
              path: "/",
              name: "welcome",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/dash.vue"),
              meta: {
              title: 'Values Beyond School'
              }
            },

            {
              path: "contact-us",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/contact/main.vue"),
              children: [
                {
                  path: "",
                  name: "contact_us",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/contact/contact.vue"),
                  meta: {
                  title: 'Contact Us'
                  }
                },
              ]
            },

            {
              path: "our-impact",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/our_impact/main.vue"),
              children: [
                {
                  path: "",
                  name: "our_impact",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/our_impact/our_impact.vue"),
                  meta: {
                  title: 'Our Impact'
                  }
                },
              ]
            },


            {
              path: "edu-shop",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/edushop/main.vue"),
              children: [
                {
                  path: "",
                  name: "edushop",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/edushop/edushop.vue"),
                  meta: {
                  title: 'Edushop'
                  }
                },
              ]
            },
            
            {
              path: "lessons",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/lessons/main.vue"),
              children: [
                {
                  path: "",
                  name: "lessons",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/lessons/lessons.vue"),
                  meta: {
                  title: 'Lessons'
                  }
                },

                {
                  path: ":id",
                  name: "lesson_details",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/lessons/details.vue"),
                  meta: {
                  title: 'Lesson Details'
                  }
                },
              ]
            },


            {
              path: "terms-and-conditions",
              name: "terms",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/pages/terms.vue"),
              meta: {
              title: 'Terms And Conditions'
              }
            },

            {
              path: "privacy-policy",
              name: "privacy",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/pages/privacy.vue"),
              meta: {
              title: 'Privacy Policy'
              }
            },

            {
              path: "technology-safety-policy",
              name: "technology",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/pages/technology.vue"),
              meta: {
              title: 'Technology Safety Policy'
              }
            },
        ]
    }
]
export default routes;