export const namespaced = true
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {

    state : {
        product :'',
        products :'',   
        categories :'',   
    },

    getters : {
        product: (state )=> state.product,
        categories: (state )=> state.categories,
        products: (state )=> state.products,
    },

    mutations : { 
        setcategories :(state,categories ) => (state.categories = categories), 
        setproduct :(state,product ) => (state.product = product), 
        setproducts :(state,products ) => (state.products = products),
    },

    actions :{
        async get_product_categories( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_product_categories')
            return new Promise((resolve, reject) => {
            axios.post("/open/products/categories?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setcategories', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_trending_products( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_trending_products')
            return new Promise((resolve, reject) => {
            axios.post("/open/products?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setproducts', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_product_details( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_product_details')
            return new Promise((resolve, reject) => {
            axios.post("/open/products/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setproduct', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }


}